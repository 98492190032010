/* Fonts */

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-Light.eot');
  src: local('Antwerp Light'), local('Antwerp-Light'),
      url('/fonts/Antwerp-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-Light.woff2') format('woff2'),
      url('/fonts/Antwerp-Light.woff') format('woff'),
      url('/fonts/Antwerp-Light.ttf') format('truetype'),
      url('/fonts/Antwerp-Light.svg#Antwerp-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-Bold.eot');
  src: local('Antwerp Bold'), local('Antwerp-Bold'),
      url('/fonts/Antwerp-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-Bold.woff2') format('woff2'),
      url('/fonts/Antwerp-Bold.woff') format('woff'),
      url('/fonts/Antwerp-Bold.ttf') format('truetype'),
      url('/fonts/Antwerp-Bold.svg#Antwerp-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-MediumItalic.eot');
  src: local('Antwerp Medium Italic'), local('Antwerp-MediumItalic'),
      url('/fonts/Antwerp-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-MediumItalic.woff2') format('woff2'),
      url('/fonts/Antwerp-MediumItalic.woff') format('woff'),
      url('/fonts/Antwerp-MediumItalic.ttf') format('truetype'),
      url('/fonts/Antwerp-MediumItalic.svg#Antwerp-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue Book';
  src: url('/fonts/MaisonNeue-Book.eot');
  src: local('Maison Neue Book'), local('MaisonNeue-Book'),
      url('/fonts/MaisonNeue-Book.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MaisonNeue-Book.woff2') format('woff2'),
      url('/fonts/MaisonNeue-Book.woff') format('woff'),
      url('/fonts/MaisonNeue-Book.ttf') format('truetype'),
      url('/fonts/MaisonNeue-Book.svg#MaisonNeue-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-Medium.eot');
  src: local('Antwerp Medium'), local('Antwerp-Medium'),
      url('/fonts/Antwerp-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-Medium.woff2') format('woff2'),
      url('/fonts/Antwerp-Medium.woff') format('woff'),
      url('/fonts/Antwerp-Medium.ttf') format('truetype'),
      url('/fonts/Antwerp-Medium.svg#Antwerp-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-SemiBold.eot');
  src: local('Antwerp SemiBold'), local('Antwerp-SemiBold'),
      url('/fonts/Antwerp-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-SemiBold.woff2') format('woff2'),
      url('/fonts/Antwerp-SemiBold.woff') format('woff'),
      url('/fonts/Antwerp-SemiBold.ttf') format('truetype'),
      url('/fonts/Antwerp-SemiBold.svg#Antwerp-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-SemiBoldItalic.eot');
  src: local('Antwerp SemiBold Italic'), local('Antwerp-SemiBoldItalic'),
      url('/fonts/Antwerp-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-SemiBoldItalic.woff2') format('woff2'),
      url('/fonts/Antwerp-SemiBoldItalic.woff') format('woff'),
      url('/fonts/Antwerp-SemiBoldItalic.ttf') format('truetype'),
      url('/fonts/Antwerp-SemiBoldItalic.svg#Antwerp-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-Regular.eot');
  src: local('Antwerp Regular'), local('Antwerp-Regular'),
      url('/fonts/Antwerp-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-Regular.woff2') format('woff2'),
      url('/fonts/Antwerp-Regular.woff') format('woff'),
      url('/fonts/Antwerp-Regular.ttf') format('truetype'),
      url('/fonts/Antwerp-Regular.svg#Antwerp-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-BoldItalic.eot');
  src: local('Antwerp Bold Italic'), local('Antwerp-BoldItalic'),
      url('/fonts/Antwerp-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-BoldItalic.woff2') format('woff2'),
      url('/fonts/Antwerp-BoldItalic.woff') format('woff'),
      url('/fonts/Antwerp-BoldItalic.ttf') format('truetype'),
      url('/fonts/Antwerp-BoldItalic.svg#Antwerp-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-Italic.eot');
  src: local('Antwerp Italic'), local('Antwerp-Italic'),
      url('/fonts/Antwerp-Italic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-Italic.woff2') format('woff2'),
      url('/fonts/Antwerp-Italic.woff') format('woff'),
      url('/fonts/Antwerp-Italic.ttf') format('truetype'),
      url('/fonts/Antwerp-Italic.svg#Antwerp-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Antwerp';
  src: url('/fonts/Antwerp-LightItalic.eot');
  src: local('Antwerp Light Italic'), local('Antwerp-LightItalic'),
      url('/fonts/Antwerp-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Antwerp-LightItalic.woff2') format('woff2'),
      url('/fonts/Antwerp-LightItalic.woff') format('woff'),
      url('/fonts/Antwerp-LightItalic.ttf') format('truetype'),
      url('/fonts/Antwerp-LightItalic.svg#Antwerp-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Maison Neue Extended';
  src: url('/fonts/MaisonNeueExtended-Light.eot');
  src: local('Maison Neue Extended Light'), local('MaisonNeueExtended-Light'),
      url('/fonts/MaisonNeueExtended-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MaisonNeueExtended-Light.woff2') format('woff2'),
      url('/fonts/MaisonNeueExtended-Light.woff') format('woff'),
      url('/fonts/MaisonNeueExtended-Light.ttf') format('truetype'),
      url('/fonts/MaisonNeueExtended-Light.svg#MaisonNeueExtended-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Extended Book';
  src: url('/fonts/MaisonNeueExtended-Book.eot');
  src: local('Maison Neue Extended Book'), local('MaisonNeueExtended-Book'),
      url('/fonts/MaisonNeueExtended-Book.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MaisonNeueExtended-Book.woff2') format('woff2'),
      url('/fonts/MaisonNeueExtended-Book.woff') format('woff'),
      url('/fonts/MaisonNeueExtended-Book.ttf') format('truetype'),
      url('/fonts/MaisonNeueExtended-Book.svg#MaisonNeueExtended-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}
